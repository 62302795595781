/* Terminal Container Styles */
.terminal-container {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
}

.terminal-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Terminal Output Styles */
.terminal-output {
  font-family: 'Cascadia Code', 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  line-height: 1.5;
  scrollbar-width: thin;
  scrollbar-color: #1f1f1f transparent;
}

.terminal-output::-webkit-scrollbar {
  width: 6px;
}

.terminal-output::-webkit-scrollbar-track {
  background: transparent;
}

.terminal-output::-webkit-scrollbar-thumb {
  background-color: #1f1f1f;
  border-radius: 3px;
}

.terminal-output::-webkit-scrollbar-thumb:hover {
  background-color: #2d2d2d;
}

/* Terminal Line Animations */
.terminal-line {
  opacity: 0;
  animation: fadeIn 0.15s ease-in forwards;
  margin-bottom: 6px;
}

.terminal-line:last-child {
  margin-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Command Types Styling */
.terminal-line .font-mono {
  letter-spacing: 0.025em;
}

.terminal-line .border-l-2 {
  padding-left: 8px;
}

/* Terminal Loader Animation */
.terminal-loader {
  width: 48px;
  height: 48px;
  position: relative;
  display: inline-block;
}

.terminal-loader::before,
.terminal-loader::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #1f1f1f;
}

.terminal-loader::before {
  animation: spin 1s infinite;
}

.terminal-loader::after {
  border-top-color: #666666;
  opacity: 0.3;
  animation: spin 1.5s infinite reverse;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Input Styles */
.terminal-input {
  position: relative;
}

.terminal-input input {
  font-family: 'Cascadia Code', 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  caret-color: #1f1f1f;
  letter-spacing: 0.025em;
}

.terminal-input input::placeholder {
  opacity: 0.6;
  font-style: italic;
}

/* Command Suggestions */
.terminal-input .mt-2 {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #1f1f1f transparent;
}

.terminal-input .mt-2::-webkit-scrollbar {
  width: 4px;
}

.terminal-input .mt-2::-webkit-scrollbar-track {
  background: transparent;
}

.terminal-input .mt-2::-webkit-scrollbar-thumb {
  background-color: #1f1f1f;
  border-radius: 2px;
}

.terminal-input .mt-2 div {
  transition: background-color 0.15s ease-in-out;
}

.terminal-input .mt-2 div:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

/* Focus and Button States */
.terminal-input input:focus {
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none;
}

button:active {
  transform: translateY(1px);
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .terminal-output,
  .terminal-input input {
    font-size: 13px;
  }
}