@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  @apply bg-gray-50 text-gray-100;
}

.btn-primary {
  @apply bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition-colors;
}

.btn-secondary {
  @apply border border-gray-600 hover:border-gray-500 text-gray-300 hover:text-white px-4 py-2 rounded transition-colors;
}

.input-field {
  @apply appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm bg-gray-800 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500;
}